import * as React from "react";
import MobileHeader from "../components/MobileHeader";

const MobileNav = () => {
  return (
    <main>
      <MobileHeader />
    </main>
  );
};

export default MobileNav;
